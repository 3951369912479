"use client"
import React, { createContext, useState, useContext } from 'react';

interface Props { }

const FormContext = createContext<any>(null);

export const FormProvider: React.FC<Props> = ({ children }: any) => {
    const [formCourseData, setFormCourseData] = useState<any>({});
    const resetFormCourseData = () => {
        setFormCourseData({});
    };

    return (
        <FormContext.Provider value={{ formCourseData, setFormCourseData, resetFormCourseData }}>
            {children}
        </FormContext.Provider>
    );
};

export const useForm = () => useContext(FormContext);